import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import FormGoalModal from '../Goals/FormGoalModal';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import { t } from 'i18next';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

function CourseStep(props) {
  const { courses, createMockQuestionBookForm, coursesLoading, goals, createGoal, questionBook } = props;
  const animatedComponents = makeAnimated();
  const [showModal, setShowModal] = useState(false);
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });
  const { session } = useSession();

  const selectOnChange = items => {
    const selectedValues = items ? items.map(i => i.value) : [];
    createMockQuestionBookForm.setFieldValue('courseId', selectedValues);
  };

  const getOptions = () => courses?.map(i => ({ value: i.id, label: i.title }));

  const selectedCourses = getOptions().filter(o => createMockQuestionBookForm.values.courseId.includes(o.value));
  return (
    <div className="new-questions__body">
      <p className="new-questions__subtitle">Faça as configurações da avaliação e em seguida, selecione as matérias.</p>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="courseId"
        >
          *{t('exams.selectCourse')}
        </label>

        {coursesLoading && <Loader />}

        {!coursesLoading && (
          <Select
            classNamePrefix="react-multi-select"
            openMenuOnFocus
            noOptionsMessage={() => 'Sem opções'}
            isDisabled={coursesLoading}
            value={selectedCourses}
            placeholder={coursesLoading ? 'Carregando...' : 'Selecione o curso'}
            onChange={selectOnChange}
            components={{
              animatedComponents,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            closeMenuOnSelect={false}
            isSearchable={true}
            options={getOptions()}
            isMulti
          />
        )}

        {createMockQuestionBookForm.touched.courseId && createMockQuestionBookForm.errors.courseId && <span style={{ color: 'red' }}>{createMockQuestionBookForm.errors.courseId}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          *{t('exams.nameSimulation')}
        </label>
        <input
          className="form__control"
          placeholder={t('exams.placeholderSimulationName')}
          id="title"
          name="title"
          type="text"
          value={createMockQuestionBookForm.values.title}
          onChange={e => createMockQuestionBookForm.setFieldValue('title', e.target.value)}
        />
        {createMockQuestionBookForm.touched.title && createMockQuestionBookForm.errors.title && <span style={{ color: 'red' }}>{createMockQuestionBookForm.errors.title}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          *{t('exams.descriptionRecommendations')}
        </label>

        <TextareaAutosize
          placeholder={t('textsCommon.writeHere')}
          id="description"
          name="description"
          value={createMockQuestionBookForm?.values?.description}
          onChange={e => createMockQuestionBookForm.setFieldValue('description', e.target.value)}
        />
        {createMockQuestionBookForm?.touched.description && createMockQuestionBookForm?.errors.description && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors.description}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="goalId"
        >
          *{'Selecione um objetivo para o simulado'}
        </label>
        <select
          className="form__select"
          name="goalId"
          id="goalId"
          onChange={e => createMockQuestionBookForm.setFieldValue('study-plan-objective-id', +e.target.value)}
          value={createMockQuestionBookForm.values['study-plan-objective-id']}
        >
          <option value="">{'Objetivo'}</option>
          {goals.map(g => (
            <option
              key={g.id}
              value={g.id}
            >
              {g.name}
            </option>
          ))}
        </select>
        <p className="new-questions__subtitle u-mt-2">
          ou clique{' '}
          <span
            className="u-cursor-pointer"
            style={{ color: 'var(--color-primary)' }}
            onClick={() => setShowModal(true)}
          >
            aqui para criar um novo objetivo
          </span>
        </p>
        {createMockQuestionBookForm.touched['study-plan-objective-id'] && createMockQuestionBookForm.errors['study-plan-objective-id'] && <span style={{ color: 'red' }}>{createMockQuestionBookForm.errors['study-plan-objective-id']}</span>}
      </div>

      <div className="form__row">
        {(!questionBook?.id || questionBook?.creator?.id === session?.user?.id || session?.user?.profileNames?.includes('Administrador')) && (
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="authorialContent"
              name="authorialContent"
              type="checkbox"
              checked={createMockQuestionBookForm?.values?.['authored_by_user']}
              onClick={() => {
                createMockQuestionBookForm?.setFieldValue('authored_by_user', !createMockQuestionBookForm?.values?.['authored_by_user']);
              }}
            />
            <label htmlFor="authorialContent">
              Este simulado é de minha autoria e <strong>somente eu posso editá-lo.</strong>
            </label>
          </div>
        )}
        {isAdmin && (
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="officialContent"
              name="officialContent"
              type="checkbox"
              checked={createMockQuestionBookForm?.values?.['official_content']}
              onClick={() => {
                createMockQuestionBookForm?.setFieldValue('official_content', !createMockQuestionBookForm?.values?.['official_content']);
              }}
            />
            <label htmlFor="officialContent">
              Este simulado é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
            </label>
          </div>
        )}
      </div>
      <FormGoalModal
        show={showModal}
        onClose={() => setShowModal(false)}
        createGoal={createGoal}
      />
    </div>
  );
}

export default CourseStep;
