import React, { useEffect, useState } from 'react';
import CountdownTimer from './CountdownTimer';
import ChallengeQuestion from './ChallengeQuestion';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function Question() {
  const { currentRound, amountRounds, currentChallenge, setCurrentAttempt, getQuestions } = useWeeklyChallenge();
  const client = useClient();
  const [currentIndexQuestion, setCurrentIndexQuestion] = useState(0);

  const percentage = (currentIndexQuestion * 100) / amountRounds;

  return (
    <section className="challenge-modal__section challenge-modal__section--question">
      <div className="challenge-modal__header">
        <div className="challenge-modal__header-content">
          <h3 className="challenge-modal__title">{currentChallenge?.taxonomy?.name}</h3>
          <p className="challenge-modal__subtitle">Rodada {currentRound}</p>
        </div>
        <CountdownTimer className="challenge-modal__countdown-timer" />
      </div>

      <div className="card__progress u-mb-0">
        <div className="card__progress-container">
          <div
            className="card__progress-bar"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div className="card__progress-text">{`${percentage?.toFixed(2)}%`}</div>
      </div>

      <ChallengeQuestion
        setCurrentIndexQuestion={setCurrentIndexQuestion}
        currentIndexQuestion={currentIndexQuestion}
      />

      {/* <QuestionCountdown /> */}
    </section>
  );
}
