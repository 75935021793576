import React, { useState } from 'react';
import MainNav from 'app/components/mainNav';
import Menu from 'app/components/Menu';
import CpfModal from 'app/components/CpfModal';
import { useSession } from 'app/hooks/useSession';

function PageWithLayouts(props) {
  const { layoutType, children } = props;
  const [showModal, setShowModal] = useState(false);
  const { session } = useSession();

  return (
    <div className={layoutType === 'mainLayout' ? 'main-screen' : 'main-screen main-screen--admin'}>
      {layoutType === 'mainLayout' && session && (
        <>
          <Menu />
          <MainNav />
          <CpfModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </>
      )}

      {children}
    </div>
  );
}

export default PageWithLayouts;
