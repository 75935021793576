import React, { useState, createContext } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export const ClassroomContext = createContext();

export const ClassroomProvider = ({ children }) => {
  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedClassroomStudents, setSelectedClassroomStudents] = useState([]);
  const [selectedClassroomProfessor, setSelectedClassroomProfessor] = useState([]);
  const [detailedClassrooms, setDetailedClassrooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedClassroomLoading, setSelectedClassroomLoading] = useState(true);
  const [classroomLoading, setClassroomLoading] = useState(false);
  const [courseModules, setCourseModules] = useState([]);
  const [modulesLoading, setModulesLoading] = useState(false);
  const [hasMoreClassrooms, setHasMoreClassrooms] = useState(false);

  const client = useClient();

  const getClassrooms = async (courseId, firstLoading = false, institutionId) => {
    setHasMoreClassrooms(true);
    try {
      setLoading(true);
      setSelectedClassroomLoading(true);
      let url = 'classrooms';

      if (courseId && courseId !== 'blank' && institutionId === 'blank') {
        url = `classrooms?filter[course_id]=${courseId}`;
      }

      if (institutionId && institutionId !== 'blank' && courseId === 'blank') {
        url = `/classrooms?filter[institution_id]=${institutionId}`;
      }

      if (institutionId && institutionId !== 'blank' && courseId && courseId !== 'blank') {
        url = `/classrooms?filter[institution_id]=${institutionId}&filter[course_id]=${courseId}`;
      }

      const { data } = await client.fetch(url);

      // If no classrooms are found in the given filter, clears the selected classroom
      // and the classrooms list safely.
      if (data.length > 0) {
        const parsedClassrooms = data.map(classroom => ({ ...classroom, name: classroom.title }));

        setClassrooms(parsedClassrooms);
        setSelectedClassroom(parsedClassrooms[0]);
        setHasMoreClassrooms(false);
        await getProfessors(parsedClassrooms[0]?.id);
        await getStudents(parsedClassrooms[0]?.id);

        if (firstLoading) {
          getStudents(parsedClassrooms[0]?.id);
          getProfessors(parsedClassrooms[0]?.id);
        }
      } else {
        setSelectedClassroom(null);
        setClassrooms([]);
        setHasMoreClassrooms(false);
      }
      setSelectedClassroomLoading(false);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setHasMoreClassrooms(false);
      toast.error(t('toast.errorGetClasses'));
    }
  };

  const getClassroomsPagination = async (searchTerm, courseId, institutionId, pageNumber = 1) => {
    setHasMoreClassrooms(true);
    setLoading(true);
    let url = `classrooms?page[size]=15&page[number]=${pageNumber}`;

    if (courseId && courseId !== 'blank' && institutionId === 'blank') {
      url += `&filter[course_id]=${courseId}`;
    }

    if (institutionId && institutionId !== 'blank' && courseId === 'blank') {
      url += `&filter[institution_id]=${institutionId}`;
    }

    if (institutionId && institutionId !== 'blank' && courseId && courseId !== 'blank') {
      url += `&filter[institution_id]=${institutionId}&filter[course_id]=${courseId}`;
    }
    if (searchTerm && searchTerm.length > 0) {
      url += `&q[q_cont_any]=${searchTerm}`;
    }

    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error(t('toast.errorGetClasses'));
      setHasMoreClassrooms(false);
    } else {
      if (data.length < 15) {
        setHasMoreClassrooms(false);
      }

      const parsedClassrooms = data.map(classroom => ({ ...classroom, name: classroom.title }));

      setClassrooms(oldState => [...oldState, ...parsedClassrooms]);
    }

    setLoading(false);
  };

  const getDetailedClassrooms = async id => {
    setClassroomLoading(true);
    const url = id === undefined ? '/classrooms?detailed=true' : `/classrooms?filter[course_id]=${id}&detailed=true`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setDetailedClassrooms(data);
    }
    setClassroomLoading(false);
  };

  const changeSelectedClassroom = async (classroom, courseId) => {
    try {
      setSelectedClassroomLoading(true);

      setSelectedClassroom(classroom);
      await getProfessors(classroom.id);
      await getStudents(classroom.id);

      setSelectedClassroomLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetStudents'));
    }
  };

  const getStudents = async classroomId => {
    try {
      const { data } = await client.fetch(`classrooms/${classroomId}/users?filter[profile]=Aluno`);
      setSelectedClassroomStudents(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetStudents'));
    }
  };

  const getProfessors = async classroomId => {
    try {
      const { data } = await client.fetch(`classrooms/${classroomId}/users?filter[profile]=Professor`);
      setSelectedClassroomProfessor(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetStudents'));
    }
  };

  const createClassroom = async form => {
    setLoading(true);
    const { error } = await client.mutate('classrooms', form);

    if (error) {
      toast.error(t('toast.errorCreateClass'));
      setLoading(false);
    } else {
      getClassrooms();
      toast.success(t('toast.successCreatedClass'));
      window.location.reload(true);
    }
  };

  const updateClassroom = async classroom => {
    try {
      await client.mutate(['classrooms', classroom.id], { title: selectedClassroom.title });

      getClassrooms();
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorEditCourse'));
    }
  };

  const deleteClassroom = async id => {
    try {
      setLoading(true);
      await client.delete(['classrooms', id]);

      getClassrooms();
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorDeleteClass'));
    }
  };

  const getCourseModules = async courseId => {
    setModulesLoading(true);
    const { data, error } = await client.fetch(`/learning_systems?filter[course_id]=${courseId}`);
    if (error) {
      toast.error('Erro ao buscar módulos');
    } else {
      setCourseModules(data.filter(item => item.publish));
    }
    setModulesLoading(false);
  };

  return (
    <ClassroomContext.Provider
      value={{
        loading,
        courseModules,
        modulesLoading,
        getCourseModules,
        selectedClassroomLoading,
        hasMoreClassrooms,
        classrooms,
        getClassrooms,
        getClassroomsPagination,
        createClassroom,
        updateClassroom,
        deleteClassroom,
        selectedClassroomStudents,
        selectedClassroomProfessor,
        selectedClassroom,
        changeSelectedClassroom,
        setSelectedClassroom,
        getDetailedClassrooms,
        detailedClassrooms,
        classroomLoading,
        setClassrooms
      }}
    >
      {children}
    </ClassroomContext.Provider>
  );
};
